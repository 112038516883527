@font-face {
    font-family: 'placa-cinza';
    src: url('../fonts/fonte_placa_cinza-webfont.woff2') format('woff2'),
        /* url('/assets/fonts/fonte_placa_cinza-webfont.woff') format('woff'); */
        url('../fonts/fonte_placa_cinza-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'placa-mercosul';
    src: url('../fonts/fonte_placa_mercosul-webfont.woff2') format('woff2'),
        url('../fonts/fonte_placa_mercosul-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.placa-cinza {
    font-family: 'placa-cinza';
    font-size: 4em;
    white-space: nowrap;
}

.placa-cinza img {
    width: 60%;
    max-width: 300px;
}

.placa-mercosul {
    font-family: 'placa-mercosul';
    font-size: 3.8em;
}

.placa-mercosul img {
    width: 60%;
    max-width: 300px;
}

.container {
    position: relative;
    text-align: center;
}

.centered {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* @media (min-width: 320px) {
    .placa-cinza{
        font-size: 2.1em;
    }
    .placa-mercosul{
        font-size: 2.4em;
    }
}
@media (min-width: 375px) {
    .placa-cinza{
        font-size: 2.5em;
    }
    .placa-mercosul{
        font-size: 2.4em;
    }
}
@media (min-width: 425px) {
    .placa-cinza{
        font-size: 2.8em;
    }
    .placa-mercosul{
        font-size: 2.8em;
    }
}
@media (min-width: 768px) {
    .placa-cinza{
        font-size: 4em;
    }
    .placa-cinza img {
        width: 50%;
    }
    .placa-mercosul{
        font-size: 3.5em;
    }
    .placa-mercosul img {
        width: 50%;
    }
}
@media (min-width: 1024px) {
    .placa-cinza{
        font-size: 4.3em;
    }
    .placa-cinza img {
        width: 40%;
    }
    .placa-mercosul{
        font-size: 3.7em;
    }
    .placa-mercosul img {
        width: 40%;
    }
}
@media (min-width: 1200px) {
    .placa-cinza{
        font-size: 6em;
    }
    .placa-cinza img {
        width: 40%;
    }
    .placa-mercosul{
        font-size: 7em;
    }
    .placa-mercosul img {
        width: 50%;
    }
} */